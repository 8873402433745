import { Outlet } from "react-router-dom";

export const BlankLayout = () => {
  document.body.classList.add("bg-gray-100", "h-full");
  return (
    <>
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <Outlet />
      </div>
    </>
  );
};
