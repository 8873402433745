import { Disclosure, Menu, Transition } from "@headlessui/react";
import { MenuIcon, UserIcon, XIcon } from "@heroicons/react/outline";
import { AcademicCapIcon } from "@heroicons/react/solid";
import { Fragment, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { UserContext } from "../../layouts";
import { joinClassNames } from "../../utils";

type NavigationItem = {
  name: string;
  href: string;
  current: boolean;
  roles: string[];
};

const navigation: NavigationItem[] = [
  {
    name: "Prüfungen",
    href: "/assessments",
    current: false,
    roles: ["chairman", "examiner"],
  },
  {
    name: "Bewertungsschemata",
    href: "/schemas",
    current: false,
    roles: ["chairman"],
  },
  {
    name: "Benutzerverwaltung",
    href: "/users",
    current: false,
    roles: ["admin"],
  },
];

const userNavigation = [
  { name: "Passwort ändern", href: "/auth/change-password" },
  { name: "Abmelden", href: "/auth" },
];

export const Navigation = () => {
  const location = useLocation();
  const [nav, setNav] = useState(navigation);
  const user = useContext(UserContext);

  useEffect(() => {
    setNav(
      nav.map((n) => {
        if (location.pathname.startsWith(n.href)) {
          n.current = true;
        } else {
          n.current = false;
        }
        return n;
      })
    );
  }, [location]);

  return (
    <>
      <Disclosure
        as="nav"
        className="bg-default-500 border-default-500 border-b sticky top-0 z-50"
      >
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex justify-between h-16">
                <div className="flex">
                  <div className="flex-shrink-0 flex items-center">
                    <Link to="/">
                      <AcademicCapIcon className="block h-8 w-auto text-white" />
                    </Link>
                  </div>
                  <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                    {navigation
                      .filter((i) =>
                        i.roles.some((r) => user?.roles.includes(r))
                      )
                      .map((item) => (
                        <Link
                          key={JSON.stringify(item)}
                          to={item.href}
                          className={joinClassNames(
                            item.current
                              ? "border-gray-100 text-white"
                              : "border-transparent text-gray-300 hover:border-gray-100 hover:text-white",
                            "inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          {item.name}
                        </Link>
                      ))}
                  </div>
                </div>
                <div className="hidden sm:ml-6 sm:flex sm:items-center">
                  {/* Profile dropdown */}
                  <Menu as="div" className="ml-3 relative">
                    <div>
                      <Menu.Button className="max-w-xs bg-transparent p-1 text-gray-50 flex items-center text-sm rounded-full">
                        <span className="sr-only">
                          Profilnavigation anzeigen
                        </span>
                        <UserIcon className="h-6 w-6" aria-hidden="true" />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {userNavigation.map((item) => (
                          <Menu.Item key={item.name}>
                            {({ active }) => (
                              <Link
                                to={item.href}
                                className={joinClassNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                              >
                                {item.name}
                              </Link>
                            )}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
                <div className="-mr-2 flex items-center sm:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-white hover:text-gray-300">
                    <span className="sr-only">Hauptmenü öffnen</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="pt-2 pb-3 space-y-1">
                {navigation
                  .filter((i) => i.roles.some((r) => user?.roles.includes(r)))
                  .map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as={Link}
                      to={item.href}
                      className={joinClassNames(
                        item.current
                          ? "bg-gray-50 border-primary text-primary"
                          : "border-transparent text-white hover:bg-gray-50 hover:border-gray-300 hover:text-primary",
                        "block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                      )}
                      aria-current={item.current ? "page" : undefined}
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
              </div>
              <div className="pt-4 pb-3 border-t border-gray-200">
                <div className="flex items-center px-4">
                  <div className="flex-shrink-0">
                    <UserIcon
                      className="h-10 w-10 text-white"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium text-white">
                      {user?.displayname}
                    </div>
                    <div className="text-sm font-medium text-gray-400">
                      {user?.email}
                    </div>
                  </div>
                </div>
                <div className="mt-3 space-y-1">
                  {userNavigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as={Link}
                      to={item.href}
                      className="block px-4 py-2 text-base font-medium text-white hover:text-primary hover:bg-gray-50"
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  );
};
