import { createClient } from "graphql-ws";
import {
  Environment,
  GraphQLResponse,
  Network,
  Observable,
  RecordSource,
  RequestParameters,
  Store,
  Variables,
} from "relay-runtime";
import fetchQuery from "./fetch-query";

const subscriptionsClient = createClient({
  // url: "ws://localhost:8083/query",
  url: "wss://api.ode8.com:8081/query",
});

const subscribe = (operation: RequestParameters, variables: Variables) => {
  return Observable.create<GraphQLResponse>((sink) => {
    if (!operation.text) {
      return sink.error(new Error("Operation text cannot be empty"));
    }
    return subscriptionsClient.subscribe(
      {
        operationName: operation.name,
        query: operation.text,
        variables,
      },
      sink as any
    );
  });
};

const network = Network.create(fetchQuery, subscribe);

export const websocketEnvironment = new Environment({
  network: network,
  store: new Store(new RecordSource()),
});
