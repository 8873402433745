import React from "react";
import { HashRouter, Navigate, Route, Routes } from "react-router-dom";
import { Loader } from "./components/layout";
import { NotFound } from "./components/layout/NotFound";
import { DashboardLayout } from "./layouts";
import { Legal } from "./pages/Leagal";

const AuthRoutes = React.lazy(() => import("./pages/auth/AuthRoutes"));
const UserRoutes = React.lazy(() => import("./pages/user/UserRoutes"));
const SchemaRoutes = React.lazy(() => import("./pages/schema/SchemaRoutes"));
const AssessmentRoutes = React.lazy(
  () => import("./pages/assessment/AssessmentRoutes")
);

function App() {
  return (
    <React.Suspense fallback={<Loader />}>
      <HashRouter basename="/">
        <Routes>
          <Route index element={<Navigate to="/assessments" />} />
          <Route
            path="auth/*"
            element={
              <React.Suspense fallback={<>Laden...</>}>
                <AuthRoutes />
              </React.Suspense>
            }
          />
          <Route path="/" element={<DashboardLayout />}>
            <Route
              path="users/*"
              element={
                <React.Suspense fallback={<>Laden...</>}>
                  <UserRoutes />
                </React.Suspense>
              }
            />
            <Route
              path="schemas/*"
              element={
                <React.Suspense fallback={<>Loading...</>}>
                  <SchemaRoutes />
                </React.Suspense>
              }
            />
            <Route
              path="assessments/*"
              element={
                <React.Suspense fallback={<>Loading...</>}>
                  <AssessmentRoutes />
                </React.Suspense>
              }
            />
          </Route>
          <Route path="*" element={<NotFound />}></Route>
          <Route path="/legal" element={<Legal />} />
        </Routes>
      </HashRouter>
    </React.Suspense>
  );
}

export default App;
