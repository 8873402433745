export const calculateGrade = (totalPoints: number): number => {
  if (totalPoints >= 100) return 1.0;
  else if (totalPoints >= 97) return 1.1;
  else if (totalPoints >= 96) return 1.2;
  else if (totalPoints >= 94) return 1.3;
  else if (totalPoints >= 92) return 1.4;
  else if (totalPoints >= 91) return 1.5;
  else if (totalPoints >= 89) return 1.6;
  else if (totalPoints >= 87) return 1.7;
  else if (totalPoints >= 86) return 1.8;
  else if (totalPoints >= 84) return 1.9;
  else if (totalPoints >= 82) return 2.0;
  else if (totalPoints >= 81) return 2.1;
  else if (totalPoints >= 79) return 2.2;
  else if (totalPoints >= 77) return 2.3;
  else if (totalPoints >= 76) return 2.4;
  else if (totalPoints >= 74) return 2.5;
  else if (totalPoints >= 72) return 2.6;
  else if (totalPoints >= 71) return 2.7;
  else if (totalPoints >= 69) return 2.8;
  else if (totalPoints >= 67) return 2.9;
  else if (totalPoints >= 66) return 3.0;
  else if (totalPoints >= 64) return 3.1;
  else if (totalPoints >= 62) return 3.2;
  else if (totalPoints >= 61) return 3.3;
  else if (totalPoints >= 59) return 3.4;
  else if (totalPoints >= 57) return 3.5;
  else if (totalPoints >= 56) return 3.6;
  else if (totalPoints >= 54) return 3.7;
  else if (totalPoints >= 52) return 3.8;
  else if (totalPoints >= 51) return 3.9;
  else if (totalPoints >= 50) return 4.0;
  else if (totalPoints >= 47) return 4.1;
  else if (totalPoints >= 46) return 4.2;
  else if (totalPoints >= 44) return 4.3;
  else if (totalPoints >= 42) return 4.4;
  else if (totalPoints >= 41) return 4.5;
  else if (totalPoints >= 39) return 4.6;
  else if (totalPoints >= 37) return 4.7;
  else if (totalPoints >= 36) return 4.8;
  else if (totalPoints >= 34) return 4.9;
  else return 5.0;
};
