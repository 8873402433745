import { Link } from "react-router-dom";

/**
 * Display Page Footer
 * @returns component
 */
export const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer aria-labelledby="footer-heading">
      <div className="mt-12 border-t border-gray-200 pt-2">
        <p className="text-base text-gray-400 text-center ">
          &copy; {year} Gradetool, All rights reserved. &nbsp;
          <Link to="/legal">Imprint & Privacy Policy</Link>
        </p>
      </div>
    </footer>
  );
};
