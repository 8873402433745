import graphql from "babel-plugin-relay/macro";
import { Buffer } from "buffer";
import React, { useEffect, useState } from "react";
import { fetchQuery } from "react-relay";
import { useNavigate } from "react-router";
import { Outlet } from "react-router-dom";
import { Footer, Navigation } from "../components/layout";
import environment from "../components/relay/environment";
import { DashboardLayoutUserQuery } from "./__generated__/DashboardLayoutUserQuery.graphql";

type User = {
  id: string;
  displayname: string;
  email: string;
  roles: string[];
};

export const UserContext = React.createContext<User | undefined>(undefined);

export const DashboardLayout = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState<User | undefined>();

  // Check if the user is authenticated. Surely this is not the best (and in no case the most elegant)
  // way but work's fine for this usecase
  useEffect(() => {
    // Retrive access token from localstorage
    const token = localStorage.getItem("accessToken");
    if (!token) {
      navigate("/auth/login");
    } else {
      // Parse payload from accessToken (see https://jwt.io)
      const tmpUser = JSON.parse(
        Buffer.from(token.split(".")[1], "base64").toString()
      );
      if (!tmpUser.username) {
        navigate("/auth/login");
      }

      // Fetch user and redirect to login when request fails
      fetchQuery<DashboardLayoutUserQuery>(
        environment,
        graphql`
          query DashboardLayoutUserQuery($email: String!) {
            userByEmail(email: $email) {
              id
              displayname
              email
              roles {
                id
                name
              }
            }
          }
        `,
        { email: tmpUser.username }
      ).subscribe({
        next: (data) => {
          setUser({
            id: data.userByEmail.id,
            displayname: data.userByEmail.displayname,
            email: data.userByEmail.email,
            roles: (data.userByEmail.roles ?? []).map((r) => r.name),
          });
        },
        error: () => navigate("/auth/login"),
      });
    }
  }, []);

  document.body.classList.add("bg-gray-100", "h-full");

  return (
    <>
      <UserContext.Provider value={user}>
        <div className="min-h-full">
          <Navigation />

          <div className="py-10">
            <main className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
              <Outlet />
            </main>
          </div>

          <Footer />
        </div>
      </UserContext.Provider>
    </>
  );
};
