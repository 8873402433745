import { Link } from "react-router-dom";

/**
 * Shows legal page
 * @returns component
 */
export const Legal = () => {
  return (
    <>
      <div className="space-y-8 max-w-3xl mx-auto pb-5">
        <h1 className="font-bold text-6xl text-center pt-20 text-gray-800">
          Legal
        </h1>

        <p className="text-center text-gray-600 text-lg">
          We display our legal texts online in English. For other languages or
          understanding problems, please contact markus.heinemann@trustify.ch.
          Thanks.
        </p>

        <p className="text-center">
          <Link
            className="text-default font-bold-500 text-lg text-default-500"
            to="/"
          >
            Back to Startpage
          </Link>
        </p>

        <h2 className="font-bold text-4xl text-center text-gray-800">
          Imprint
        </h2>

        <p className="mt-5">
          <b className="block mb-2">Contact details</b>
          NAVEST GmbH <br />
          Markus Heinemann <br />
          Gliseralee 144 <br />
          3902 Brig-Glis <br />
          <br />
          markus.heinemann@trustify.ch <br />
          +41 27 558 09 09 <br />
          <br />
          Company No. (UID): CHE-237.045.759
          <b className="block mb-2 mt-8">Disclaimer</b>
          No responsibility is taken for the correctness, accuracy, timeliness,
          reliability and completeness of information. Liability claims
          regarding any material or immaterial damage caused by the access, use
          or non-use of any information provided, arisen due to misuse of the
          connection or due to technical faults, etc. will therefore be rejected
          as far as legally permissible. Despite careful control of the content,
          we assume no liability for the content of external links. The
          operators of the linked pages are solely responsible for their
          content. The use of or access to this website is at the visitor's own
          risk.
          <b className="block mb-2 mt-8">Copyright</b>
          The adoption and use of the texts, images, video, representations and
          data is prohibited unless GRADETOOL has given its consent. The logo
          and color compositions may not be used elsewhere. The copyrights and
          all other rights to content, images, videos, photos or other files on
          the website belong exclusively to GRADETOOL or the specifically named
          copyright holders. For the reproduction of any elements, the written
          consent of the copyright holders must be obtained in advance. Anyone
          who commits a copyright infringement without the consent of the
          respective rights holder may be liable to prosecution and possibly to
          compensation for damages.
          <b className="block mb-2 mt-8">Further regulations & information</b>
          We cannot guarantee the security of data transmission on the Internet;
          in particular, there is a risk of access by third parties when data is
          transmitted. The European Commission provides a platform for
          out-of-court online dispute resolution (ODR platform). We do not
          participate. Should individual provisions or formulations of this
          imprint be or become invalid, this shall not affect the content or
          validity of the remaining provisions. In the event of disputes, Swiss
          law applies exclusively and the place of jurisdiction is Switzerland,
          Brig.
        </p>

        <h2 className="font-bold text-4xl text-center text-gray-800">
          Privacy Policy
        </h2>

        <p>
          The responsible party within the meaning of the data protection laws,
          in particular the EU General Data Protection Regulation (GDPR /
          DSGVO), is:
        </p>

        <p>
          NAVEST GmbH <br />
          Markus Heinemann <br />
          Gliseralee 144 <br />
          3902 Brig-Glis <br />
          <br />
          Phone: +41 27 558 09 09 <br />
          E-Mail: info@trustify.ch <br />
        </p>

        <p>
          <b className="block mb-2 mt-8">General note</b>
          Based on Article 13 of the Swiss Federal Constitution and the data
          protection provisions of the Swiss Confederation (Data Protection Act,
          DSG), every person has the right to protection of their privacy as
          well as protection against misuse of their personal data. The
          operators of these pages take the protection of your personal data
          very seriously. We treat your personal data confidentially and in
          accordance with the legal data protection regulations as well as this
          privacy policy.
          <br />
          <br />
          In cooperation with our hosting providers, we make every effort to
          protect the databases as well as possible against unauthorized access,
          loss, misuse or falsification.
          <br />
          <br />
          We would like to point out that data transmission on the Internet
          (e.g. communication by e-mail) can have security gaps. A complete
          protection of data against access by third parties is not possible.
          <br />
          <br />
          By using this website, you consent to the collection, processing and
          use of data in accordance with the following description. This website
          can generally be visited without registration. Data such as pages
          accessed or names of files accessed, date and time are stored on the
          server for statistical purposes without this data being directly
          related to your person. Personal data, in particular name, address or
          e-mail address are collected as far as possible on a voluntary basis.
          Without your consent, the data will not be passed on to third parties.
          <b className="block mb-2 mt-8">Processing of personal data</b>
          Personal data is any information that relates to an identified or
          identifiable person. A data subject is a person about whom personal
          data is processed. Processing includes any handling of personal data,
          regardless of the means and procedures used, in particular the
          storage, disclosure, acquisition, deletion, storage, modification,
          destruction and use of personal data.
          <br />
          <br />
          We process personal data in accordance with Swiss data protection law.
          Furthermore, we process personal data - to the extent and insofar as
          the EU GDPR is applicable - in accordance with the following legal
          bases in connection with Art. 6 (1) GDPR:
          <ul className="mt-5 space-y-5">
            <li>
              lit. a) Processing of personal data with the consent of the data
              subject.
            </li>
            <li>
              lit. b) Processing of personal data for the fulfillment of a
              contract with the data subject as well as for the implementation
              of corresponding pre-contractual measures.
            </li>
            <li>
              lit. c) Processing of personal data for the fulfillment of a legal
              obligation to which we are subject under any applicable law of the
              EU or under any applicable law of a country in which the GDPR is
              applicable in whole or in part.
            </li>
            <li>
              lit. d) Processing of personal data in order to protect the vital
              interests of the data subject or another natural person.
            </li>
            <li>
              lit. f) Processing of personal data to protect the legitimate
              interests of us or of third parties, unless the fundamental
              freedoms and rights and interests of the data subject are
              overridden. Legitimate interests are in particular our business
              interest in being able to provide our website, information
              security, the enforcement of our own legal claims and compliance
              with Swiss law.
            </li>
          </ul>
          <br />
          <br />
          We process personal data for the duration required for the respective
          purpose or purposes. In the case of longer-term retention obligations
          due to legal and other obligations to which we are subject, we
          restrict processing accordingly.
          <b className="block mb-2 mt-8">
            Privacy policy for SSL/TLS encryption
          </b>
          This website uses SSL/TLS encryption for security reasons and to
          protect the transmission of confidential content, such as requests
          that you send to us as the site operator. You can recognize an
          encrypted connection by the fact that the address line of the browser
          changes from "http://" to "https://" and by the lock symbol in your
          browser line. If SSL or TLS encryption is activated, the data you
          transmit to us cannot be read by third parties.
          <b className="block mb-2 mt-8">
            Data transmission security (without SSL)
          </b>
          Please note that data transmitted over an open network such as the
          Internet or an e-mail service without SSL encryption can be viewed by
          anyone. You can recognize an unencrypted connection by the fact that
          the address line of the browser shows "http://" and no lock symbol is
          displayed in your browser line. Information transmitted over the
          Internet and content received online may be transmitted over
          third-party networks. We cannot guarantee the confidentiality of any
          communications or materials transmitted over such open or third-party
          networks.
          <br />
          <br />
          If you disclose personally identifiable information over an open
          network or third-party networks, you should be aware that your
          information may be lost or potentially accessed by third parties and,
          as a result, the information may be collected and used without your
          consent. While in many cases the individual data packets are
          transmitted in encrypted form, the names of the sender and recipient
          are not. Even if the sender and the recipient reside in the same
          country, the data transfer via such networks often takes place without
          controls even via third countries, i.e. also via countries that do not
          offer the same level of data protection as your country of domicile.
          We assume no responsibility for the security of your data during
          transmission over the Internet and disclaim any liability for direct
          or indirect losses. We ask you to use other means of communication
          should you consider this necessary or reasonable for security reasons.
          <br />
          <br />
          Despite extensive technical and organizational security precautions,
          it is possible that data may be lost or intercepted and/or manipulated
          by unauthorized persons. As far as possible, we take appropriate
          technical and organizational security measures to prevent this within
          our system. However, your computer is outside the security perimeter
          under our control. It is your responsibility as a user to inform
          yourself about the necessary security precautions and to take
          appropriate measures in this regard. As the website operator, we are
          in no way liable for any damage that you may suffer as a result of
          data loss or manipulation.
          <br />
          <br />
          Data that you enter in online forms may be passed on to authorized
          third parties for the purpose of order processing and may be viewed
          and possibly processed by them.
          <b className="block mb-2 mt-8">Privacy policy for server log files</b>
          The provider of this website automatically collects and stores
          information in so-called server log files, which your browser
          automatically transmits to us. These are: browser type and browser
          version, operating system used, referrer URL, host name of the
          accessing computer and time of the server request. This data cannot be
          assigned to specific persons. This data is not merged with other data
          sources. We reserve the right to check this data retrospectively if we
          become aware of specific indications of illegal use.
          <b className="block mb-2 mt-8">
            Privacy policy for the use of Google Web Fonts
          </b>
          This website uses so-called web fonts provided by Google for the
          uniform display of fonts. When you call up a page, your browser loads
          the required web fonts into its browser cache in order to display
          texts and fonts correctly. If your browser does not support web fonts,
          a standard font is used by your computer. You can find more
          information on Google Web Fonts at
          https://developers.google.com/fonts/faq and in Google's privacy
          policy: https://www.google.com/policies/privacy/
          <b className="block mb-2 mt-8">General disclaimer</b>
          All information on our website has been carefully checked. We make
          every effort to ensure that the information we provide is up-to-date,
          correct and complete. Nevertheless, the occurrence of errors can not
          be completely excluded, so we can not guarantee the completeness,
          accuracy and timeliness of information, including
          journalistic-editorial nature. Liability claims regarding damage
          caused by the use of any information provided, including any kind of
          information which is incomplete or incorrect, will therefore be
          rejected.
          <br />
          <br />
          The publisher may change or delete texts at his own discretion and
          without notice and is not obliged to update the contents of this
          website. The use of or access to this website is at the visitor's own
          risk. The publisher, its clients or partners are not responsible for
          damages, such as direct, indirect, incidental, consequential or
          punitive damages, allegedly caused by the visit of this website and
          consequently assume no liability for such damages.
          <br />
          <br />
          The publisher also accepts no responsibility or liability for the
          content and availability of third-party websites that can be accessed
          via external links on this website. The operators of the linked sites
          are solely responsible for their content. The publisher thus expressly
          distances itself from all third-party content that may be relevant
          under criminal or liability law or that may offend common decency.
          <b className="block mb-2 mt-8">Changes</b>
          We may change this privacy policy at any time without prior notice.
          The current version published on our website applies.
          <b className="block mb-2 mt-8">
            Questions for the data protection officer
          </b>
          If you have any questions about data protection, please send us an
          e-mail or contact the person in our organization responsible for data
          protection listed at the beginning of this privacy statement directly.
          <br />
          <br />
          Should individual provisions or formulations of this privacy policy be
          or become invalid, this shall not affect the content or validity of
          the remaining provisions. In the event of disputes, Swiss law applies
          exclusively and the place of jurisdiction is Switzerland, Brig.
          <br />
          <br />
          Brig-Glis, 10.09.2021
          <br />
          <br />
          Credits: SwissAnwalt
        </p>
      </div>
    </>
  );
};
