import { Variables } from "react-relay";
import { GraphQLResponse, ObservableFromValue } from "relay-runtime";

// export const GRAPHQL_URL = "http://localhost:8083/query";
export const GRAPHQL_URL = "https://api.ode8.com:8081/query";

let headers = {
  Accept: "application/json",
  "Content-type": "application/json",
};

// Define a function that fetches the results of a request (query/mutation/etc)
// and returns its results as a Promise:
const fetchQuery = (
  operation: any,
  variables: Variables
): ObservableFromValue<GraphQLResponse> => {
  const body = JSON.stringify({
    query: operation.text, // GraphQL text from input
    variables,
  });

  const accessToken = localStorage.getItem("accessToken");
  if (accessToken) {
    headers = { ...headers, ...{ Authorization: accessToken } };
  }

  const response = fetch(GRAPHQL_URL, {
    method: "POST",
    headers,
    body,
  }).then(async (res) => {
    const body = await res.json();
    if (body.errors) {
      if (body.errors[0].message === "input: not permitted") {
        localStorage.clear();
        window.location.hash = "/auth/login";
      }
    }
    return body;
  });

  return response;
};

export default fetchQuery;
